/**
 * Copyright © 2016-2022 The Thingsboard Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@use "sass:math";

@mixin tb-mat-icon-size($size) {
  width: #{$size}px;
  min-width: #{$size}px;
  height: #{$size}px;
  min-height: #{$size}px;
  font-size: #{$size}px;
  line-height: #{$size}px;
  svg {
    width: #{$size}px;
    height: #{$size}px;
  }
}

@mixin tb-mat-icon-button-size($size) {
  width: #{$size}px;
  height: #{$size}px;
  line-height: #{$size}px;
  padding: 0 !important;
  .mat-icon {
    display: block;
    margin: auto;
  }
}

@mixin tb-checkered-bg() {
  background-color: #fff;
  background-image:
    linear-gradient(45deg, #ddd 25%, transparent 25%, transparent 75%, #ddd 75%, #ddd),
    linear-gradient(45deg, #ddd 25%, transparent 25%, transparent 75%, #ddd 75%, #ddd);
  background-position: 0 0, 4px 4px;
  background-size: 8px 8px;
}

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - math.div($x0 * $x0 - abs($r), 2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}
