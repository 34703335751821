/**
 * Copyright © 2016-2022 The Thingsboard Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
$mat-xs: "screen and (max-width: 599px)";
$mat-sm: "screen and (min-width: 600px) and (max-width: 959px)";
$mat-md: "screen and (min-width: 960px) and (max-width: 1279px)";
$mat-lg: "screen and (min-width: 1280px) and (max-width: 1919px)";
$mat-xl: "screen and (min-width: 1920px) and (max-width: 5000px)";
$mat-lt-sm: "screen and (max-width: 599px)";
$mat-lt-md: "screen and (max-width: 959px)";
$mat-lt-lg: "screen and (max-width: 1279px)";
$mat-lt-xl: "screen and (max-width: 1919px)";
$mat-gt-xs: "screen and (min-width: 600px)";
$mat-gt-sm: "screen and (min-width: 960px)";
$mat-gt-md: "screen and (min-width: 1280px)";
$mat-gt-xmd: "screen and (min-width: 1600px)";
$mat-gt-xl: "screen and (min-width: 1920px)";

$primary-hue-3: rgb(207, 216, 220) !default;
